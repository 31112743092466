import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";
import MediaQuery from "../../..//layout/MediaQuery";

const Gradient = Styled.div`
  background: radial-gradient(${(props) => props.alpha != undefined ? "rgba(68, 20, 187, "+props.alpha+")" : "rgb(68, 20, 187)"}, rgb(23, 9, 56) 50%, rgb(4, 4, 4), rgb(3, 3, 3));
  width: 100%;
  height: 75%;
  position: absolute !important;
  overflow: visible !important;
  top: 0%;
  z-index: -1;
`;

const ImageMobile = Styled(Image)`
  width: 100%;
  position: absolute !important;
  overflow: visible !important;
  top: 0;
  z-index: -1;
`;

const ImageWeb = Styled(Image)`
  width: 100%;
  position: absolute !important;
  overflow: visible !important;
  top: 0;
  z-index: -1;
`;

type BannerProps = {
  backgroundGridWeb: GatsbyImageProps;
  backgroundGridMobile: GatsbyImageProps;
  alpha?: Number;
};

const BackgroundMerlin = ({
  backgroundGridWeb, backgroundGridMobile, alpha
}: BannerProps) => (
  <>
    <Gradient alpha={alpha}/>
    <MediaQuery lessThan="medium">
      <ImageMobile
          fluid={"fluid" in backgroundGridMobile ? backgroundGridMobile.fluid : null}
          alt="futuristic grid design background, shades of blue, purple, and black"
        />
      </MediaQuery>

      <MediaQuery greaterThan="medium">
        <ImageWeb
          fluid={"fluid" in backgroundGridWeb ? backgroundGridWeb.fluid : null}
          alt="futuristic grid design background, shades of blue, purple, and black"
        />
    </MediaQuery>
  </>
);

export default BackgroundMerlin;
