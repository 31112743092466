import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

import Content from 'components/layout/Content/Content';
import Headline from 'components/texts/Headline';

const Container = Styled.div`
  position: relative;
  margin: 4rem 0 2rem 0;

  ${Media.greaterThan('medium')`
    margin: 6rem 0 4rem 0;
  `}
`;

const HeadlineExtended = Styled(Headline)`
  font-size: 3.5rem;

  ${Media.lessThan('medium')`
    font-size: 2.5rem;
  `}
`;

const Blogs = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.greaterThan('1100px')`
    flex-direction: row;
  `}
`;

const BlogHighlight = Styled.div`
  text-align: center;
  width: 100%;
  margin: 2.5rem auto 0 auto;

  ${Media.greaterThan('1100px')`
    margin: 0 2.5rem 0 0;

    &:last-child {
      margin: 0 0 0 0;
    }
  `}

  ${Media.greaterThan('medium')`
    width: fit-content;
  `}
`;

const Link = Styled.a`
  &:hover, &:focus {
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
  }
`;

const Image = Styled.img`
  display: block;
  width: 100%;
  height: auto;

  ${Media.greaterThan('medium')`
    height: 18rem;
    width: auto;
  `}

  ${Media.greaterThan('1420px')`
    height: 25rem !important;
  `}

  ${Media.greaterThan('1200px')`
    height: 20rem;
  `}
`;

const ImageContainer = Styled.div`
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.11);

  &:after {
    content: 'Read more';
    font-weight: bold;
    color: ${(props) => props.theme.colors.white};
    padding-top: 25%;
    align-content: center;
    font-size: 2rem;
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    left:0;
    background: rgba(0,0,0,0.45);
    opacity: 0;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
  }

  &:hover:after {
    opacity: 1;
  }
`;

type BlogProps = {
  title?: string;
  blogs: Array<{
    image: string;
    link: string;
    alt: string;
  }>;
};

const BlogHighlightSection = ({
  title,
  blogs,
}: BlogProps) => (
  <Container>
    <Content>
      {title && (
        <HeadlineExtended>{title}</HeadlineExtended>
      )}
      <Blogs>
        {blogs.map((blog) => (
          <BlogHighlight>
            <Link href={blog.link} target="_blank">
              <ImageContainer>
                <Image src={blog.image} alt={blog.alt} />
              </ImageContainer>
            </Link>
          </BlogHighlight>
        ))}
      </Blogs>
    </Content>
  </Container>
);

export default BlogHighlightSection;
