import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

import Description from 'components/texts/Description';
import Headline from 'components/texts/Headline';

const Column = Styled.div`
  text-align: center;
`;

const ColumnText = Styled(Column)`
  margin-bottom: 4rem;
  padding-right: 0;

  ${Media.greaterThan('large')`
    margin-bottom: 0;
  `}
`;

const Header = Styled.div`
  max-width: 100%;
  margin: 0 auto;
`;

const HeadlineWrapper = Styled.div`
  max-width: 100%;
  margin: 0;
  
  svg {
    max-width: 100%;
  }

  ${Media.greaterThan('medium')`
    margin: 0;
  `}
`;

const DescriptionExtended = Styled(Description)`
  width: 100%;
  margin: 0 auto 3rem auto;
  font-size: 2.5rem;
  font-weight: 500;

  ${Media.greaterThan('700px')`
    width: 80%;
    font-size: 3rem;
    margin-bottom: 3rem;
    letter-spacing: -1px;
  `}
`;

const ExtendedHeadline = Styled(Headline)`
  text-align: center;
  font-size: 4.5rem;
  line-height: 4.5rem;
  max-width: 100%;
  margin: 0 0 4rem 0;
  font-weight: 500;
  letter-spacing: -2px;
  
  ${Media.greaterThan('medium')`
    font-size: 6rem;
    line-height: 6rem;
    letter-spacing: -3px;
    max-width: 75%;
    margin: 0 auto 4rem auto;
  `}

  ${Media.greaterThan('large')`
    font-size: 8.5rem;
    line-height: 8.5rem;
    letter-spacing: -4px;
    max-width: 100%;
    margin: 0 auto 4rem auto;
  `}
`;

const HeaderMerlin = () => (
  <ColumnText>
    <Header>
      <HeadlineWrapper>
        <ExtendedHeadline as="h1">
          Autodesigner 1.5 is here!
        </ExtendedHeadline>
      </HeadlineWrapper>

      <DescriptionExtended>
        Now advance any Uizard project by generating new screens, images, and themes directly from the Uizard editor.
        Autodesigner 1.5 empowers you to design using just your imagination and a few simple words.
      </DescriptionExtended>
    </Header>
  </ColumnText>
);

export default HeaderMerlin;
