import * as React from "react";
import Styled from "styled-components/macro";

import Description from "./Description";


const ContentLink = Styled.a`
  font-weight: bold;
  color: ${(props) =>
    !props.dark ? "black" : "white"};
`;

const Container = Styled.div`
`;

type DescriptionProps = {
  content: string;
  dark?: boolean;
};

const RichDescription = ({ content, dark }: DescriptionProps) => {
  return (
    <Container>
      {content && content.split("<br/>").map((line) => {
        if (line.indexOf("<a:") != -1) {
          const href = line.substring(
            line.indexOf("<a:") + 3,
            line.indexOf(">")
          );
          const text = line.substring(
            line.indexOf(">") + 1,
            line.indexOf("</a>")
          );
          return (
            <Description>
              {line.substring(0, line.indexOf("<a:"))}
              <ContentLink dark = {dark}href={href}>{text}</ContentLink>
              {line.substring(line.indexOf("</a>") + 4)}
            </Description>
          );
        }
        return <Description>{line}</Description>;
      })}
    </Container>
  );
};

export default RichDescription;
