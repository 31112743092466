import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import MediaQuery from 'components/layout/MediaQuery';
import Headline from 'components/texts/Headline';
import RichDescription from 'components/texts/RichDescription';
import Tagline from 'components/texts/Tagline';

const Container = Styled.div`
  margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 ${(props) => (props.marginBottom ? props.marginBottom : '2rem')} 0;
  overflow: hidden; // fix for columns overflow
  
    ${Media.greaterThan('700px')`
     margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 ${(
      props,
    ) => (props.marginBottom ? props.marginBottom : '12rem')} 0;
  `}
`;

const Columns = Styled.div`
   ${Media.greaterThan('700px')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
  
  ${Media.greaterThan('large')`
    height: 55rem;
  `}
`;

const Column = Styled.div`
  position: relative;
  width: 100%;
  
  ${Media.greaterThan('700px')`
     padding-right: 3rem;
     width: 50%;
  `}
  
  ${Media.greaterThan('medium')`
    width: 45%;
  `}
`;

const ColumnIllustration = Styled(Column)`
  width: 100%;
  height: 42rem;
  display: flex;
  align-items: center;
  padding-right: 0;
      
  ${Media.greaterThan('700px')`
      height: auto;
      width: 50%;
      margin-top: 0;
  `}
  
  position: relative;
`;

const Text = Styled.div`
  max-width: 100%;
  
  ${Media.greaterThan('700px')`
      width: 43rem;
  `}

  ${Media.greaterThan('large')`
    width: calc(43rem + 9.5rem);
    padding-right: 9.5rem;
  `}
`;

const RichDescriptionExtended = Styled(RichDescription)`
  ${Media.greaterThan('medium')`
    margin-bottom: 0;
  `}
`;

const ImageMobile = Styled(Image)`
  width: 34rem;
  margin: 0 0 3rem 0;
`;

const ImageWrapper = Styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  
  ${Media.greaterThan('medium')`
    top: 50%;
  `}
  
  ${Media.greaterThan('large')`
    left: 50%;
  `}
  
  ${Media.greaterThan('1400px')`
    left: 45%;
  `}
`;

const ImageDesktop = Styled(Image)`
  width: 36.3rem;
  max-width: calc(100% - 0.75rem);
  
  ${Media.greaterThan('medium')`
    max-width: 100%;
  `}

  ${Media.greaterThan('1024px')`
    width: 40rem;
  `}
 
  ${Media.greaterThan('large')`
    width: 50rem;
  `}
  
    ${Media.greaterThan('1400px')`
    width: 66rem;
  `}
`;

const Box = Styled.div`
  width: 32rem;
  height: 24rem;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.gray.lighter};
  
   ${Media.greaterThan('700px')`
    width: 100%;
    margin: 0;
  `}
  
  ${Media.greaterThan('1400px')`
    height: 39rem;
  `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const NavButtonLink = Styled.a`
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
  margin-top: 3rem;

  ${Media.greaterThan('medium')`
    margin-bottom: 0;
  `}

  ${Media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

type HomeFeedbackProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  marginTop?: string;
  marginBottom?: string;
  tagAsTitle?: boolean;
  link?: boolean;
  description2?: string;
};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  marginTop,
  marginBottom,
  tagAsTitle,
  link,
  description2,
}: HomeFeedbackProps) => (
  <Container marginTop={marginTop} marginBottom={marginBottom}>
    <Content>
      <Columns>
        <Column>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            <RichDescriptionExtended content={description} />

            {description2 && (
              <RichDescriptionExtended content={description2} />
            )}

            {link && (
              <NavButtonLink href={link}>
                <ExtendedButton variant="primarylight" solid>
                  Read more
                </ExtendedButton>
              </NavButtonLink>
            )}
          </Text>
        </Column>

        <ColumnIllustration>
          <ImageWrapper>
            <MediaQuery lessThan="1024px">
              <ImageMobile
                fluid={'fluid' in imageB ? imageB.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>

            <MediaQuery greaterThan="1024px">
              <ImageDesktop
                fluid={'fluid' in imageA ? imageA.fluid : null}
                alt={imageAlt}
              />
            </MediaQuery>
          </ImageWrapper>
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);
