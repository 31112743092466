import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import { GatsbyImageProps } from 'gatsby-image';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import MediaQuery from 'components/layout/MediaQuery';
import BackgroundMerlin from 'components/pages/merlin/BackgroundMerlin';
import HeaderMerlin from 'components/pages/merlin/HeaderMerlin';
import VideoMerlin from 'components/pages/merlin/VideoMerlin';

const Container = Styled.div`
  padding: 5rem 0 10rem 0;
  overflow: hidden; // fix for columns overflow
  position: relative;

  ${Media.greaterThan('medium')`
    padding: 5rem 0 20rem 0;
  `}
`;

const ContentExtended = Styled(Content)`
  width: 107rem;
  max-width: calc(100% - 4rem);
  z-index: 1;
  position: relative;
`;

const VideoContainer = Styled(Content)`
  width: 140rem;
  max-width: calc(100% - 4rem);
  position: relative;
  cursor: pointer;

  &:hover button {
    opacity: 1;
  }
`;

const Columns = Styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const VideoIframeContainer = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const VideoFrame = Styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const YoutubeIframe = Styled.iframe`
  overflow: hidden;
  border-radius: 32px;
  border: none;
  box-shadow: 0px -20px 200px rgba(68, 20, 187, 0.5);
`;

const CloseButton = Styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20px !important;
  right: 20px !important;
  cursor: pointer;
`;

const NavButtonLink = Styled.a`
  margin: auto;
`;

const ButtonExtended = Styled(Button)`
  border: none;
  color: #FFFFFF;
  background: linear-gradient(to right, #0094FF, #7000FF);
  border-radius: 16px;
  height: 6.2rem;

  &:active {
    background: radial-gradient(#7000FF, #0094FF);
  }
`;

const PHBadge = Styled.div`
  margin: 0 auto 4rem auto;
`;

const VideoIframe = ({
  onClose,
}) => (
  <VideoIframeContainer onClick={onClose}>
    <CloseButton onClick={onClose} style={{ position: 'absolute', top: '0', right: '0' }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12" fill="none" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="1" y1="11" x2="11" y2="1" stroke="white" strokeWidth="2" />
        <line x1="1" y1="1" x2="11" y2="11" stroke="white" strokeWidth="2" />
      </svg>
    </CloseButton>
    <VideoFrame>
      <MediaQuery lessThan="medium">
        <YoutubeIframe
          width={Math.round(window.innerWidth - window.innerWidth * 0.2)}
          height={Math.round((window.innerWidth - window.innerWidth * 0.2) / (1 / 1))}
          src="https://www.youtube.com/embed/gNwg-MaHEe0"
          title="The Autodesigner 1.5 Update is Here!"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </MediaQuery>
      <MediaQuery greaterThan="medium">
        <YoutubeIframe
          width={Math.round(window.innerWidth - window.innerWidth * 0.2)}
          height={Math.round((window.innerWidth - window.innerWidth * 0.2) / (16 / 9))}
          src="https://www.youtube.com/embed/gNwg-MaHEe0"
          title="The Autodesigner 1.5 Update is Here!"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </MediaQuery>
    </VideoFrame>
  </VideoIframeContainer>
);

const VideoDiv = ({
  imageVideo,
}) => {
  const [showVideo, setShowVideo] = React.useState(false);

  const handleOpenVideo = () => {
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
  };

  return (
    <React.Fragment>
      <VideoContainer onClick={handleOpenVideo}>
        <VideoMerlin imageVideo={imageVideo} />
      </VideoContainer>
      {showVideo && <VideoIframe onClose={handleCloseVideo} />}
    </React.Fragment>
  );
};

type BannerProps = {
  imageVideo: GatsbyImageProps;
  backgroundGridWeb: GatsbyImageProps;
  backgroundGridMobile: GatsbyImageProps;
};

const PageMerlin = ({
  imageVideo,
  backgroundGridWeb,
  backgroundGridMobile,
}: BannerProps) => (
  <Container>
    <ContentExtended>

      <Columns>
        {/* <PHBadge>
          <a href="https://www.producthunt.com/posts/uizard-autodesigner?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-uizard&#0045;autodesigner" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=398671&theme=neutral" alt="Uizard&#0032;Autodesigner - Text&#0032;to&#0032;design&#0044;&#0032;automated&#0032;by&#0032;AI | Product Hunt" width="250" height="54" /></a>
         </PHBadge> */}

        <HeaderMerlin />
        {/* <FormMerlin/>
        <NavButtonLink
              href={`https://${constants.APP_DOMAIN}/auth/sign-up`}
            >
              <ButtonExtended solid>Sign up</ButtonExtended>
        </NavButtonLink> */}

      </Columns>

    </ContentExtended>

    <BackgroundMerlin
      backgroundGridWeb={backgroundGridWeb}
      backgroundGridMobile={backgroundGridMobile}
    />

    <VideoDiv imageVideo={imageVideo} />

  </Container>
);

export default PageMerlin;
