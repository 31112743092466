import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

export interface MediaQueryProps {
  lessThan?: string;
  greaterThan?: string;
  between?: string;
}

const MediaQuery = Styled.div<MediaQueryProps>`
  display: none;

  ${(props) => props.lessThan
    && Media.lessThan(props.lessThan)`
    display: block;
  `}

  ${(props) => props.greaterThan
    && Media.greaterThan(props.greaterThan)`
    display: block;
  `}

  ${(props) => props.between
    && Media.between(props.between[0], props.between[1])`
    display: block;
  `}
`;

export default MediaQuery;
