import * as React from "react";
import Styled from "styled-components/macro";
import Button from "../Button";

const MagicButton = Styled(Button)`
  border: none;
  color: #FFFFFF;
  background-color: #0094FF;
  background: linear-gradient(to right, #0094FF, #7000FF);
  border-radius: 16px;
  height: 6.2rem;

  &:active {
    background: radial-gradient(#7000FF, #0094FF);
  }
`;

export default MagicButton;