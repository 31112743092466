import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

const Tagline = Styled.span`
  display: block;
  color: ${(props) => props.theme.colors.gray.medium};
  font-size: 1.6rem;
  font-weight: 600;
  margin: 0 0 1.5rem 0;
  
  ${Media.greaterThan("large")`
    font-size: 2rem;
    margin: 0 0 3rem 0;
  `}
`;

export default Tagline;
