import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Styled from 'styled-components/macro';

import Content from 'components/layout/Content/Content';
import MediaQuery from 'components/layout/MediaQuery';

interface VideoProps {
  square?: boolean;
}

const Column = Styled.div`
  text-align: center;
`;

const ColumnIllustration = Styled(Column)`
  margin-top: 3rem;

  ${Media.greaterThan('medium')`
    margin-top: 6rem;
  `}
`;

const w = 1440;
const h = 765;

const VideoContainer = Styled.div`
  position: relative;
  height: 0;
  width: ${w}px;
  max-width: 100%;
  padding-bottom: 100%;
  margin: 0 auto;
  box-shadow: 0px -20px 200px rgba(68, 20, 187, 0.75);
  border-radius: 32px;
  overflow: hidden;
  
  ${Media.greaterThan('medium')`
    margin: 0 auto;
    padding-bottom: ${Math.round((h / w) * 100)}%;
  `}
`;

const VideoContent = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Video = Styled.video<VideoProps>`
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;

`;

const ImageA = Styled(Image).attrs({
  style: {
    position: 'absolute',
  },
})`
  position: absolute;
  width: 100%;
  z-index: 0;
`;

type BannerProps = {
  imageVideo: GatsbyImageProps;
};

const VideoMerlin = ({
  imageVideo,
}: BannerProps) => (
  <Content>
    <ColumnIllustration>
      <VideoContainer>
        <VideoContent id="video-frame">
          <MediaQuery lessThan="medium">
            <Video autoPlay muted loop playsInline>
              <source src="/videos/uizard-autodesigner-v1.5-square.mp4" type="video/mp4" />
              <source src="/videos/uizard-autodesigner-v1.5-square.ogv" type="video/ogv" />
              <source src="/videos/uizard-autodesigner-v1.5-square.webm" type="video/webm" />
              <img
                src="/videos/YT_Thumbnail_Autodesigner1.5.png"
                alt="Hero video showing the technology of Uizard Autodesigner in context with a use case."
              />
            </Video>
            <ImageA
              fluid={'fluid' in imageVideo ? imageVideo.fluid : null}
              alt="Hero video showing the technology of Uizard Autodesigner in context with a use case."
            />
          </MediaQuery>
          <MediaQuery greaterThan="medium">
            <Video autoPlay muted loop playsInline>
              <source src="/videos/uizard-autodesigner-v1.5-landscape.mp4" type="video/mp4" />
              <source src="/videos/uizard-autodesigner-v1.5-landscape.ogv" type="video/ogv" />
              <source src="/videos/uizard-autodesigner-v1.5-landscape.webm" type="video/webm" />
              <img
                src="/videos/YT_Thumbnail_Autodesigner1.5.png"
                alt="Hero video showing the technology of Uizard Autodesigner in context with a use case."
              />
            </Video>
            <ImageA
              fluid={'fluid' in imageVideo ? imageVideo.fluid : null}
              alt="Hero video showing the technology of Uizard Autodesigner in context with a use case."
            />
          </MediaQuery>
        </VideoContent>
      </VideoContainer>
    </ColumnIllustration>
  </Content>
);

export default VideoMerlin;
