import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import { HybridLink } from 'components/layout/Footer';
import Description from 'components/texts/Description';
import Headline from 'components/texts/Headline';

const Container = Styled.div`
  position: relative;
  margin: 6rem 0 4rem 0;

  ${Media.greaterThan('medium')`
    margin: 8rem 0 6rem 0;
  `}
`;

const ContentFAQ = Styled(Content)`
  width: 107rem;
`;

const HeadlineExtended = Styled(Headline)`
  font-size: 3.5rem;
  font-weight: 500;

  ${Media.lessThan('medium')`
    font-size: 2.5rem;
  `}
`;

const Questions = Styled.div`
`;

const QuestionContainer = Styled.details`
    border-top: 1px solid ${(props) => (props.color === true ? '#2E2E2E' : '#EEEEEE')};

    &[open] summary div:nth-child(1) {
      display: none;
    }

    &[open] summary div:nth-child(2) {
      display: block;
    }
`;

const QuestionLabel = Styled.summary`
    font-size: 3rem;
    line-height: 3.6rem;
    font-weight: 500;
    letter-spacing: -0.3px;
    margin: 2rem 0 2rem 0;
    cursor: pointer;
    position: relative;
    list-style: none;

    &::-webkit-details-marker {
      display:none;
    }

    ${Media.lessThan('medium')`
      font-size: 2rem;
    `}
`;

const Arrow = Styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
`;

const ArrowDown = Styled(Arrow)``;

const ArrowUp = Styled(Arrow)`
  display: none;
`;

const Answer = Styled(Description)`
  margin-bottom: 3rem;
`;

const ExtendedLink = Styled(HybridLink)`
`;

const ButtonExtended = Styled(Button)`
    display: block;
    border-radius: 1rem;
    font-size: 1.6rem;
`;

type FAQProps = {
  dark?: boolean;
  title: string;
  questions: Array<{
    question: string;
    answer: string;
    buttonText?: string;
    buttonLink?: string;
  }>;
};

const FAQSection = ({
  dark,
  title,
  questions,
}: FAQProps) => (
  <Container>
    <ContentFAQ>
      <HeadlineExtended>{title}</HeadlineExtended>
      <Questions>
        {questions.map((question) => (
          <QuestionContainer color={dark}>
            <QuestionLabel>
              {question.question}
              <ArrowDown>
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="6 9 12 15 18 9" /></svg>
              </ArrowDown>
              <ArrowUp>
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="18 15 12 9 6 15" /></svg>
              </ArrowUp>
            </QuestionLabel>
            <Answer>
              {question.answer}
            </Answer>

            {question.buttonText && question.buttonLink && (
              <ExtendedLink to={question.buttonLink}>
                <ButtonExtended
                  solid
                  type="submit"
                >
                  {question.buttonText}
                </ButtonExtended>
              </ExtendedLink>
            )}
          </QuestionContainer>
        ))}
      </Questions>
    </ContentFAQ>
  </Container>
);

export default FAQSection;
