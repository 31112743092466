import Media from "@uizard-io/styled-media-query";
import constants from "constants/index";
import Image, { GatsbyImageProps } from "gatsby-image";
import * as React from "react";
import Styled from "styled-components/macro";
import MagicButton from "./buttons/MagicButton";
import Content from "./layout/Content/Content";
import MediaQuery from "./layout/MediaQuery";
import Headline from "./texts/Headline";
import RichDescription from "./texts/RichDescription";
import Tagline from "./texts/Tagline";

const ImageMobile = Styled(Image)`
`;

const ImageDesktop = Styled(Image)`
  width: 100%;
`;
const ImgContainer = Styled.div`

`;

const Columns = Styled.div`
    display: flex;
    margin-top: 10rem;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    
    ${Media.greaterThan("900px")`
        flex-direction: row;
        margin-top: 18rem;
    `}

    ${Media.greaterThan("medium")`
      margin-top: 15rem;
    `}
`;

const ColumnText = Styled.div`
    width: 100%;
    
     ${Media.greaterThan("900px")`
        width: 50%;
    `}
`;

const ColumnIllustration = Styled.div`
    width: 75%;
    max-width: 399px;
    margin-top: 5rem;
    
     ${Media.greaterThan("900px")`
        margin-top: 0;
        max-width: 634px;
        margin-left: 9rem;
    `}
`;

const SignupContainer = Styled.div`
  max-width: 33.5rem;
  
  ${Media.greaterThan("900px")`
        max-width: 43rem;
    `}
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const NavButtonLink = Styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 6rem;

  ${Media.greaterThan("medium")`
      display: block;
      margin-top: 0;
  `}
`;

const ContentExtended = Styled(Content)`
    margin-bottom: 10rem;
    
    ${Media.greaterThan("900px")`
        margin-bottom: 12rem;
    `}
`;

//PSA change to fit the image/video dimensions or itll look off
const w = 750;
const h = 440;

const VideoContainer = Styled.div`
  position: relative;
  height: 0;
  width: ${w}px;
  max-width: 100%;
  padding-bottom: ${(props) => props.videoH ? Math.round((props.videoH / w) * 100) : Math.round((h / w) * 100)}%;
  margin: 0 auto;
  box-shadow: 0px 2px 20px rgba(3,3,3,0.1);
  border-radius: 30px;
  overflow: hidden;
  ${Media.greaterThan("medium")`
    margin: 0;
  `}
`;

const VideoContent = Styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Video = Styled.video`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
`;

export type LandingPageProps = {
    headline: string,
    tagline?: string,
    description: string,
    imageA: GatsbyImageProps,
    imageB: GatsbyImageProps,
    imageAlt?: string,
    videoName?: string,
    videoH?: number,
    ctaText?: string,
  }

const LandingPageSection = ({headline, tagline, description, imageA, imageB, imageAlt, videoName, videoH, ctaText="Sign up for free"}: LandingPageProps) => {
  return (
    <ContentExtended narrow>
    <Columns>
      <ColumnText>
        {tagline && <TaglineExtended as="h1">{tagline}</TaglineExtended>}
        <Headline as="h2">{headline}</Headline>
        <RichDescription content={description} />

        <NavButtonLink href={`https://${constants.APP_DOMAIN}/sign-up`}>
          <MagicButton solid>{ctaText}</MagicButton>
        </NavButtonLink>
      </ColumnText>
      <ColumnIllustration>
      <VideoContainer videoH={videoH}>
            <VideoContent>
              <Video autoPlay muted loop playsInline>
                <source
                  src={"/videos/" + videoName + ".mp4"}
                  type="video/mp4"
                />
                <source
                  src={"/videos/" + videoName + ".ogv"}
                  type="video/ogv"
                />
                <source
                  src={"/videos/" + videoName + ".webm"}
                  type="video/webm"
                />
                <img
                  src={"/videos/" + videoName + ".png"}
                  alt={"Video of " + imageAlt}
                />
              </Video>
            </VideoContent>

            <ImgContainer>
              <MediaQuery lessThan="medium">
                <ImageMobile
                  fluid={"fluid" in imageB ? imageB.fluid : null}
                  alt={imageAlt}
                />
              </MediaQuery>

              <MediaQuery greaterThan="medium">
                <ImageDesktop
                  fluid={"fluid" in imageA ? imageA.fluid : ""}
                  alt={imageAlt}
                />
              </MediaQuery>
            </ImgContainer>
          </VideoContainer>
      </ColumnIllustration>
    </Columns>
  </ContentExtended>
  )
}

export default LandingPageSection




