import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Image, { GatsbyImageProps } from 'gatsby-image';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import MediaQuery from 'components/layout/MediaQuery';
import Description from 'components/texts/Description';
import Headline from 'components/texts/Headline';
import RichDescription from 'components/texts/RichDescription';
import Tagline from 'components/texts/Tagline';

const Container = Styled.div`
  margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 ${(props) => (props.marginBottom ? props.marginBottom : '4rem')} 0;
  overflow: hidden; // fix for columns overflow
  
    ${Media.greaterThan('700px')`
     margin: ${(props) => (props.marginTop ? props.marginTop : 0)} 0 ${(
      props,
    ) => (props.marginBottom ? props.marginBottom : '12rem')} 0;
  `}
`;

const Columns = Styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverseOrder ? 'column' : 'column-reverse')};
  gap: 1rem;

  ${Media.greaterThan('700px')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
  `}
`;

const ColumnImage = Styled.div`
  position: relative;

  ${Media.greaterThan('700px')`
    width: 50%;
  `}
`;

const ColumnText = Styled.div`
  ${Media.greaterThan('large')`
    &:first-of-type {
      padding-left: 9rem;
    }
  `}
`;

const Text = Styled.div`
  max-width: 100%;

  ${Media.greaterThan('large')`
    width: calc(43rem + 9.5rem);
  `}
`;

const DescriptionExtended = Styled(Description)`
  margin-bottom: 3rem;

  ${Media.greaterThan('medium')`
    margin-bottom: 0;
  `}
`;

const RichDescriptionExtended = Styled(RichDescription)`
  margin-bottom: 3rem;

  ${Media.greaterThan('medium')`
    margin-bottom: 0;
  `}
`;

const ImageMobile = Styled(Image)`
`;

const ImageDesktop = Styled(Image)`
  width: 100%;
`;

const TaglineExtended = Styled(Tagline)`
  color: #7f7f7f;
`;

const ExtendedButton = Styled(Button)`
  margin-bottom: 5rem;
  margin-top: 3rem;

  ${Media.greaterThan('medium')`
    margin-bottom: 0;
  `}

  ${Media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const ImageA = Styled(Image)`
  width: 32.3rem;
  max-width: calc(100% - 0.75rem);
  border-radius: 8px;
  
  ${Media.greaterThan('700px')`
    margin: 0 auto;
  `}
  
  ${Media.greaterThan('1280px')`
    width: 60rem;
  `}

  ${Media.greaterThan('760px')`
    max-width: 100%;
  `}

  ${Media.lessThan('1280px')`
    width: 45rem;
  `}
`;

const NavButtonLink = Styled.a`
`;

type HomeWireframeProps = {
  tagline?: string;
  headline: string;
  description: string;
  imageA: GatsbyImageProps;
  imageB: GatsbyImageProps;
  imageAlt: string;
  link?: string;
  description2?: string;
  tagAsTitle?: boolean;
  marginTop?: string;
  marginBottom?: string;
  reverseOrder?: boolean;
};

export default ({
  tagline,
  headline,
  description,
  imageA,
  imageB,
  imageAlt,
  link,
  description2,
  tagAsTitle,
  marginTop,
  marginBottom,
  reverseOrder,
}: HomeWireframeProps) => (
  <Container marginTop={marginTop} marginBottom={marginBottom}>
    <Content>
      <Columns reverseOrder={reverseOrder}>
        <ColumnImage>
          <MediaQuery lessThan="medium">
            <ImageA
              fluid={'fluid' in imageB ? imageB.fluid : ''}
              alt={imageAlt}
            />
          </MediaQuery>
          <MediaQuery greaterThan="medium">
            <ImageA
              fluid={'fluid' in imageA ? imageA.fluid : ''}
              alt={imageAlt}
            />
          </MediaQuery>
        </ColumnImage>

        <ColumnText>
          <Text>
            {tagline && tagAsTitle && (
              <TaglineExtended as="h1">{tagline}</TaglineExtended>
            )}
            {tagAsTitle && <Headline as="h2">{headline}</Headline>}

            {tagline && !tagAsTitle && (
              <TaglineExtended>{tagline}</TaglineExtended>
            )}
            {!tagAsTitle && <Headline>{headline}</Headline>}

            <RichDescriptionExtended content={description} />

            {description2 && (
              <DescriptionExtended>
                {description2}
              </DescriptionExtended>
            )}

            {link && (
              <NavButtonLink href={link}>
                <ExtendedButton variant="primarylight" solid>
                  Read more
                </ExtendedButton>
              </NavButtonLink>
            )}
          </Text>
        </ColumnText>
      </Columns>
    </Content>
  </Container>
);
