import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import { graphql } from 'gatsby';
import Styled from 'styled-components/macro';

import LandingPageSection from 'components/LandingPageSection';
import Layout from 'components/layout/Layout';
import YoutubeEmbed from 'components/layout/YoutubeEmbed/YoutubeEmbed';
import Meta from 'components/meta';
import BlogHighlight from 'components/pages/blog/BlogHighlight';
import blogThumbnail2 from 'components/pages/blog/BlogThumbnails/BLOG_230529_UizardAutodesigner.webp';
import blogThumbnail3 from 'components/pages/blog/BlogThumbnails/BLOG_230830_What-is-prompt-engineering_.webp';
import blogThumbnail1 from 'components/pages/blog/BlogThumbnails/BLOG_230913_AguidetoUizard-sMagicFeatures.webp';
import FAQSection from 'components/pages/faq/FAQSection';
import PageMerlin from 'components/pages/merlin/PageMerlin';
import SectionWithLeftImage from 'components/pages/solutions/SectionWithLeftImage';
import SectionWithRightImage from 'components/pages/solutions/SectionWithRightImage';

const FAQContainer = Styled.div`
  padding: 0 0 3rem 0;
  overflow: hidden; // fix for columns overflow
  position: relative;

  ${Media.greaterThan('medium')`
    padding: 0 0 5rem 0;
  `}
`;

const MerlinWrapper = ({
  data,
}) => (
  <Layout type="dark" news="hide">
    <Meta
      title="Uizard Autodesigner 1.5 | AI UI Design Generator | Uizard"
      description="Autodesigner 1.5 is here! Generate individual screens, images, or themes directly from the Uizard editor. Sign up for Uizard Pro today."
      url="/autodesigner/"
      metaimage="https://uizard.io/opengraph-autodesigner-2023-02-17.png"
      faqschema='{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is Autodesigner?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Autodesigner is the latest AI design feature from Uizard that enables users to generate multi screen mockups by typing out project ideas in plain English. Autodesigner is pretty much like ChatGPT but for UI/UX design! Joining Uizard&apos;s library of AI-powered features, Autodesigner will help bring your vision to life in seconds; simply enter a few simple phrases and let AI bring your app vision to life in a matter of seconds."
            }
          },
          {
            "@type": "Question",
            "name": "Who is Autodesigner for?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Whether you are a design pro or a complete novice, Autodesigner is here to streamline your productivity. Generate and test your ideas and concepts faster than ever before with the power of generative AI. Whether you are a project manager, a marketer, a startup founder, or a developer, Uizard Autodesigner will assist you in turning your ideas into reality."
            }
          },
          {
            "@type": "Question",
            "name": "Can I customize what Autodesigner creates?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes! Any design that Autodesigner generates is fully editable and customizable within Uizard&apos;s easy-to-use, drag-and-drop editor. Once Autodesigner has generated your project, you can update copy, add new UI components, make your design interactive, and you can even import themes from other design sources to change styling. Uizard is built for teams so you can also invite your colleagues and co-create in real-time."
            }
          },
          {
            "@type": "Question",
            "name": "Does Uizard have other AI features?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes! Uizard has a powerful library of AI design features that make design easy and accessible. Uizard was built AI-first from day one when the company was founded in 2018. In a way, you could call us the OGs of AI design technology. (cringe)"
            }
          }
        ]
      }'
    />

    <LandingPageSection
      headline="Uizard Autodesigner 1.5"
      description="Generate editable prototypes, screens, images, and themes using simple text prompts."
      imageA={data.textAssistantA.childImageSharp}
      imageB={data.textAssistantB.childImageSharp}
      videoName="ai-landing-autodesign"
      imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
      ctaText="Sign up now"
      videoH={500}
    />

    {/* <GoogleReCaptchaProvider reCaptchaKey={constants.RECAPTCHA_SITE_KEY}> */}
    <PageMerlin
      imageVideo={data.bannerImageA.childImageSharp}
      backgroundGridWeb={data.backgroundGridWeb.childImageSharp}
      backgroundGridMobile={data.backgroundGridMobile.childImageSharp}
    />
    {/* </GoogleReCaptchaProvider> */}

    <SectionWithRightImage
      headline="Generate stunning, editable designs"
      description="Never get stuck staring at a blank screen again."
      description2="Enter your design idea in plain English, specify a theme, and watch as Autodesigner creates an AI generated UI design in no time at all."
      imageA={data.autodesignerOverviewBig.childImageSharp}
      imageB={data.autodesignerOverviewSmall.childImageSharp}
      imageAlt="Overview of a design generated by Autodesigner"
    />

    <SectionWithLeftImage
      headline="Prototypes, themes, and screens in a flash"
      description="You bring the ideas, AI does the rest. Ideation and iteration has never been so quick and easy."
      description2="Use Autodesigner to generate a whole prototype and then update your project with new screens, themes, and images using simple text prompts."
      imageA={data.autodesignerInteractionBig.childImageSharp}
      imageB={data.autodesignerInteractionSmall.childImageSharp}
      imageAlt="Overview of a design with interaction between components generated by Autodesigner"
    />

    <SectionWithRightImage
      headline="Edit your design with ease"
      description="Editing your Autodesigner project is easy with Uizard's drag-and-drop editor."
      description2="Update text, add new images, generate new themes, and create clickable user journeys with ease."
      imageA={data.autodesignerEditingBig.childImageSharp}
      imageB={data.autodesignerEditingSmall.childImageSharp}
      imageAlt="Overview of a design generated by Autodesigner"
    />

    <YoutubeEmbed
      videoW={1220}
      videoH={686}
      url="https://www.youtube.com/embed/wixX1_39gqI"
    />

    <BlogHighlight
      title="Learn more about Autodesigner"
      blogs={[
        {
          image: blogThumbnail1,
          link: 'https://uizard.io/blog/uizard-magic-features-guide/',
          alt: 'Thumbnail for blog titled The complete guide to Uizard’s Magic Features',
        },
        {
          image: blogThumbnail2,
          link: 'https://uizard.io/blog/how-to-generate-a-multi-screen-app-design-from-a-text-prompt/',
          alt: 'Thumbnail for blog titled How to generate a multi-screen app design from a text prompt',
        },
        {
          image: blogThumbnail3,
          link: 'https://uizard.io/blog/what-is-prompt-engineering/',
          alt: 'Thumbnail for blog titled What is prompt engineering? Prompt engineering tutorial',
        },
      ]}
    />

    <FAQContainer>
      <FAQSection
        dark
        title="More about Uizard Autodesigner"
        questions={[
          {
            question: 'What is Autodesigner?',
            answer:
              "Autodesigner is the latest AI design feature from Uizard that enables users to generate multi screen mockups by typing out project ideas in plain English. Autodesigner is pretty much like ChatGPT but for UI/UX design! Joining Uizard's library of AI-powered features, Autodesigner will help bring your vision to life in seconds; simply enter a few simple phrases and let AI bring your app vision to life in a matter of seconds.",
          },
          {
            question: 'What is the Autodesigner 1.5 update?',
            answer:
              'The Autodesigner 1.5 update introduces a host of new features including Screen Generator, which allows users to generate screens from text prompts for existing projects, as well as Image Generator and Theme Generator. All these new features are accessible from the brand-new Autodesigner widget which can be found at the bottom of the editor.',
          },
          {
            question: 'Who is Autodesigner for?',
            answer:
              'Whether you are a design pro or a complete novice, Autodesigner is here to streamline your productivity. Generate and test your ideas and concepts faster than ever before with the power of generative AI. Whether you are a project manager, a marketer, a startup founder, or a developer, Uizard Autodesigner will assist you in turning your ideas into reality.',
          },
          {
            question: 'Can I customize what Autodesigner creates?',
            answer:
              "Yes! Any design that Autodesigner generates is fully editable and customizable within Uizard's easy-to-use, drag-and-drop editor. Once Autodesigner has generated your project, you can update copy, add new UI components, make your design interactive, and you can even import themes from other design sources to change styling. Uizard is built for teams so you can also invite your colleagues and co-create in real-time.",
          },
          {
            question: 'Does Uizard have other AI features?',
            answer:
              'Yes! Uizard has a powerful library of AI design features that make design easy and accessible. Uizard was built AI-first from day one when the company was founded in 2018. In a way, you could call us the OGs of generative AI for design. (cringe 😬)',
            buttonText: 'Read more about Uizard AI',
            buttonLink: '/design-assistant/',
          },
        ]}
      />
    </FAQContainer>

  </Layout>
);

export default MerlinWrapper;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageA: file(
      relativePath: { eq: "merlin/uizard-autodesigner-big.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    textAssistantA: file(relativePath: { eq: "ai/dark-autodesigner-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    textAssistantB: file(relativePath: { eq: "ai/dark-autodesigner-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerOverviewBig: file(relativePath: { eq: "ai/autodesignerOverviewBig.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerOverviewSmall: file(relativePath: { eq: "ai/autodesignerOverviewSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerInteractionBig: file(relativePath: { eq: "ai/autodesignerInteractionBig.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerInteractionSmall: file(relativePath: { eq: "ai/autodesignerInteractionSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerEditingBig: file(relativePath: { eq: "ai/autodesignerEditingBig.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerEditingSmall: file(relativePath: { eq: "ai/autodesignerEditingSmall.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    backgroundGridWeb: file(relativePath: { eq: "merlin/grid-web.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    backgroundGridMobile: file(relativePath: { eq: "merlin/grid-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
