import * as React from 'react';

import Media from '@uizard-io/styled-media-query';
import Styled from 'styled-components/macro';

import Content from 'components/layout/Content/Content';

export interface YoutubeIframeProps {
  lightMode?: boolean;
}

export interface VideoContainerProps {
  lightMode?: boolean;
}

const YoutubeIframe = Styled.iframe<YoutubeIframeProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto 10rem auto;
  border: none;
  box-shadow: 0px -20px 200px rgba(68, 20, 187, 0.75);
  border-radius: 32px;
  overflow: hidden;

  ${props => props.lightMode && `
    box-shadow: none;
  `};

  ${Media.greaterThan('large')`
    height: 686px;
  `};
`;

const w = 1220;
const h = 686;

const VideoContainer = Styled.div<VideoContainerProps>`
  position: relative;
  height: 0;
  padding-top: 30px;
  padding-bottom: ${(props) => (props.videoH || props.videoH ? Math.round(((props.videoH ? props.videoH : h) / (props.videoW ? props.videoW : w)) * 100) : Math.round((h / w) * 100))}%;
  margin: 0 auto;
  box-shadow: 0px -20px 200px rgba(68, 20, 187, 0.75);
  border-radius: 32px;
  overflow: hidden;

  ${props => props.lightMode && `
    box-shadow: none;
    margin: 10rem 0;
  `};
  
  ${Media.greaterThan('medium')`
    margin: 0 auto;

    ${props => props.lightMode && `
      margin: 15rem 0;
    `}
  `}

  ${Media.greaterThan('large')`
    height: 686px;
    padding-bottom: 52%;
  `};
`;

type YoutubeEmbedProps = {
  url: string;
  videoW: number;
  videoH: number;
  lightMode?: boolean;
};

const YoutubeEmbed = ({
  url,
  videoW,
  videoH,
  lightMode,
}: YoutubeEmbedProps) => (
  <Content>
    <VideoContainer videoW={videoW} videoH={videoH} lightMode={lightMode}>
      <YoutubeIframe
        width={videoW}
        height={videoH}
        src={url}
        title="Uizard Autodesigner: Generate an App Design from a Text Prompt"
        allowfullscreen
        lightMode={lightMode}
      />
    </VideoContainer>
  </Content>
);

export default YoutubeEmbed;
